import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';

const status = {
  NOT_STARTED: 'notstarted',
  IN_PROGRESS: 'inprogress',
  PAUSED: 'paused',
  SUBMITTING: 'submitting',
  COMPLETED: 'completed'
};

interface ParticipantState {
    survey: any;
    client: any;
    page: number;
    participant: any;
    responses: any[];
    validations: any;
    status: string;
    startedAt?: Date;
    pausedAt?: Date;
    error: any;
    lastTotalTime: number;
}

const state: ParticipantState = {
  survey: null,
  client: null,
  page: 1,
  participant: { fullName: '' },
  responses: [],
  validations: {},
  status: status.NOT_STARTED,
  startedAt: undefined,
  pausedAt: undefined,
  error: null,
  lastTotalTime: 0
};

// expired

const actions = {
  async startSurvey ({ commit }) {
    commit('startSurvey');
  },
  async pauseSurvey ({ commit }) {
    commit('pauseSurvey');
  },
  async restartSurvey ({ commit }) {
    commit('resetSurvey');
    commit('resetTime');
    commit('resetResponses');
  },
  async prevPage ({ commit, getters }) {
    if (getters.hasPrevPage) {
      commit('setPage', state.page - 1);
    } else {
      commit('resetSurvey');
    }
  },
  async nextPage ({ commit, getters }, { preview = false }) {
    if (getters.isLastPage) {
      if (preview) {
        return;
      }
      state.status = status.SUBMITTING;

      commit('setError', {
        // heading: 'Submitting Responses...',
        message: 'Submitting responses...please wait.'
      });

      // TODO: should we handle this else where??
      const now = new Date();
      const totalTime = Math.round(state.lastTotalTime + moment(now).diff(moment(state.startedAt)));
      const data = {
        fullName: state.participant.fullName ? state.participant.fullName : null,
        responses: state.responses,
        totalTime: Math.round(totalTime / 1000)
      };
      const surveyUrl = `/participants/surveys/${getters.survey.slug}/responses?client=${getters.survey.client.slug}&preview=${preview}`;
      return axios.post(surveyUrl, data).then(() => {
        state.status = status.COMPLETED;
        commit('setError', {
          heading: 'Survey Complete',
          message: 'Thank you for participating in this survey. Your feedback is important.'
        });
      }).catch((err) => {
        if (err && err.status === 410) {
          commit('setError', {
            heading: 'Survey Closed',
            message: 'Unfortunately, the survey you are trying to access is now closed. Please contact your administrator for further assistance.'
          });
        } else if (err && err.status === 412) {
          commit('setError', {
            heading: 'Survey at Capacity',
            message: 'The maximum number of participants have completed this survey. Please contact your administrator for further assistance.'
          });
        } else {
          commit('setError', {
            heading: 'Failed to submit response',
            message: 'Unfortunately, there was a problem to submit your survey response. Please contact your administrator for further assistance.'
          });
        }
      });
    } else {
      commit('setPage', state.page + 1);
    }
  },
  async getClient ({ commit }, { client }) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    };
    const clientUrl = `/participants/clients/${client}`;
    return axios.get(clientUrl, config)
      .then((res) => {
        const client = res.data;
        commit('setClient', client);
        return client;
      });
  },
  async getSurvey ({ commit }, { client, surveySlug, preview = false }) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    };
    const surveyUrl = `/participants/surveys/${surveySlug}?client=${client}&preview=${preview}`;
    return axios.get(surveyUrl, config)
      .then((res) => {
        const survey = res.data;
        commit('setSurvey', survey);
        return survey;
      })
      .catch((err) => {
        if (err.status === 403) {
          // forbidden @ upcoming
          commit('setError', {
            heading: 'Survey Not Yet Open',
            message: 'Unfortunately, the survey you are trying to access has not yet started. Please contact your administrator for further assistance.'
          });
        } else if (err.status === 409) {
          // duplicate
          // this.duplicate = true; // TODO: USE generic status instead
          commit('setError', {
            heading: 'You have already completed this survey',
            message: 'Unfortunately, it is only possible for you to complete the survey once. Please contact your administrator for further assistance.'
          });
        } else if (err.status === 410) {
          // gone @ expired
          commit('setError', {
            heading: 'Survey Closed',
            message: 'Unfortunately, the survey you are trying to access is now closed. Please contact your administrator for further assistance.'
          });
        } else if (err.status === 412) {
          // gone @ expired
          commit('setError', {
            heading: 'Survey at Capacity',
            message: 'The maximum number of participants have completed this survey. Please contact your administrator for further assistance.'
          });
        } else {
          // this.notfound = true;
          commit('setError', {
            heading: 'Page Not Working',
            message: 'Unfortunately, the webpage you are trying to access is currently not working. Please contact your administrator for further assistance.'
          });
        }
        throw err;
      });
  }
};

const ID = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

const mutations = {
  startSurvey (state) {
    if (state.status === status.NOT_STARTED) {
      state.status = status.IN_PROGRESS;
      if (!state.startedAt) {
        state.startedAt = new Date();
      }
    } else if (state.status === status.PAUSED) {
      state.status = status.IN_PROGRESS;
      state.startedAt = new Date();
    }
  },
  pauseSurvey (state) {
    if (state.status === status.IN_PROGRESS) {
      const now = new Date();
      state.pausedAt = now;
      state.lastTotalTime = state.lastTotalTime + moment(now).diff(moment(state.startedAt));
      state.startedAt = null;
      state.status = status.PAUSED;
    }
  },
  resetTime (state) {
    state.lastTotalTime = 0;
    state.startedAt = null;
    state.pausedAt = null;
  },
  resetSurvey (state) {
    state.status = status.NOT_STARTED;
    state.page = 1;
  },
  resetResponses (state) {
    state.participant = { fullName: '' };
    state.responses = [];
  },
  setClient (state, data) {
    state.client = data;
  },
  setSurvey (state, data) {
    const responsesValidation: any[] = [];
    data.template.pages.forEach(page => {
      page.elements.forEach(element => {
        state.responses.push({
          title: element.title
        });

        const validation: Record<string, any> = {
          title: {
            required
          }
        };
        if (element.required) {
          validation.text = {
            required
          };
        }
        responsesValidation.push(validation);
      });
    });
    const validations: {
      responses: any;
      participant: any;
    } = {
      responses: { ...responsesValidation },
      participant: {}
    };
    if (data.template.enabledFeatures.completionTracking === true) {
      validations.participant.fullName = {
        required
      };
    }
    state.validations = validations;
    state.survey = data;
  },
  setPage (state, page) {
    state.page = page;
  },
  setError (state, error) {
    state.error = error;
  }
};

const getters = {
  startedAt: state => state.startedAt,
  pausedAt: state => state.pausedAt,
  lastTotalTime: state => state.lastTotalTime,
  error: state => state.error,
  status: state => state.status,
  participant: state => state.participant,
  responses: state => state.responses,
  survey: state => state.survey,
  expired: state => state.survey.status === 'completed',
  page: state => state.survey ? state.survey.template.pages[state.page - 1] : {},
  pages: state => state.survey ? state.survey.template.pages : [],
  totalPages: (state, getters) => getters.pages.length,
  isLastPage (state, getters) {
    return state.page === getters.totalPages;
  },
  hasNextPage (state, getters) {
    return state.page < getters.totalPages;
  },
  hasPrevPage (state) {
    return state.page > 1;
  },
  progressValue (state, getters) {
    let total = 0;
    for (let i = 1; i < state.page && i < getters.pages.length; i++) {
      total = total + (getters.pages[i - 1].elements).length;
    }
    const totalQuestions = getters.template.totalQuestions;
    return total / totalQuestions * 100;
  },
  template (state, getters) {
    return state.survey ? state.survey.template : null;
  },
  clientLogo (state, getters) {
    return state.client ? state.client.logo : null;
  },
  surveyDesign (state, getters) {
    return state.client ? state.client.surveyDesign : {};
  },
  validationObject: state => state.validations
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
