import Vue from 'vue';
import Vuex from 'vuex';

/* Modules */
import auth from './modules/auth';
import client from './modules/client';
import survey from './modules/survey';
import template from './modules/template';
import user from './modules/user';
import report from './modules/report';
import participant from './modules/participant';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    client,
    survey,
    template,
    user,
    report,
    participant
  },
  state: {},
  actions: {},
  getters: {},
  mutations: {}
});
