import Vue from 'vue';
import axios from 'axios';

const state = {
  users: {},
  count: {},
  roles: []
};

const actions = {
  async create ({ commit }, { data }) {
    return axios.post('/users', data)
      .then(({ data }) => {
        return data;
      });
  },
  async get ({ commit }, { id, data }) {
    return axios.get(`/users/${id}`, data)
      .then(({ data }) => {
        return data;
      });
  },
  async update ({ commit }, { id, data }) {
    return axios.put(`/users/${id}`, data)
      .then(({ data }) => {
        return data;
      });
  },
  async delete ({ commit }, { id }) {
    return axios.delete(`/users/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  async list ({ commit }, { key = 'default', params = {} }) {
    return axios.get('/users', { params })
      .then(({ data }) => {
        commit('setList', { key, data });
        return data;
      });
  },
  async count ({ commit }, { key = 'default', params = {} }) {
    return axios.get('/users/count', { params })
      .then(({ data }) => {
        commit('setCount', { key, data });
        return data;
      });
  },
  async getRoles ({ commit }) {
    return axios.get('/users/roles')
      .then(({ data }) => {
        commit('setRoles', data);
        return data;
      });
  }
};

const mutations = {
  setList (state, { key = 'default', data }) {
    const users = state.users[key];
    Vue.set(state.users, key, data);
  },
  setCount (state, { key = 'default', data }) {
    Vue.set(state.count, key, data);
  },
  setRoles (state, data) {
    state.roles.splice(0);
    state.roles.push(...data);
  }
};

const getters = {
  getList: (state) => (key = 'default') => {
    return state.users[key];
  },
  getCount: (state) => (key = 'default') => {
    return state.count[key];
  },
  roles: state => state.roles
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
