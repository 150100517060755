import axios from 'axios';

const state = () => ({
  status: '',
  user: null
});

const actions = {
  async login ({ commit }, { username = '', password = '' } = {}) {
    commit('inflight');
    return axios.post('/auth/login', { username, password })
      .then(result => {
        commit('login', result.data);
        return result;
      }).catch(err => {
        commit('error');
        throw err;
      });
  },
  logout ({ commit }) {
    commit('inflight');
    return axios.get('/auth/logout')
      .then(() => {
        commit('logout');
        return true;
      }).catch(err => {
        commit('error');
        throw err;
      });
  },
  forgot ({ commit }, { username }) {
    return axios.post('/auth/forgot', { username });
  },
  verify ({ commit }, { token, password, confirmPassword }) {
    return axios.post('/auth/verify', { token, password, confirmPassword });
  },
  resend ({ commit }, { username }) {
    return axios.post('/auth/resend', { username });
  },
  updatePassword ({ commit }, { data }) {
    return axios.put('/auth/password', data);
  },
  resetPassword ({ commit }, { token, password, confirmPassword }) {
    return axios.post('/auth/reset', { token, password, confirmPassword });
  },
  // connect({
  //     commit,
  //     dispatch
  // }, payload) {
  //     commit('inflight');
  //     return this._vm.$auth.connect()
  //         .then(result => {
  //             return dispatch('validate');
  //         }).catch(err => {
  //             commit('error');
  //             throw err;
  //         });
  // },
  validate ({ commit }) {
    commit('inflight');
    return axios.get('/auth')
      .then(result => {
        // const user = result.auth.user ? result.auth.user : null;
        commit('validate', result.data);
        return result;
      }).catch(err => {
        commit('error');
        throw err;
      });
  }
};

const mutations = {
  inflight: state => {
    state.status = 'processing';
  },
  login: (state, payload) => {
    state.status = 'success';
    state.user = payload;
  },
  validate: (state, payload) => {
    state.status = 'success';
    state.user = payload;
  },
  error: state => {
    state.status = 'error';
  },
  logout: state => {
    state.status = 'success';
    state.user = null;
  }
};

const getters = {
  status: state => state.status,
  currentUser: state => state.user,
  isAdmin: state => state.user && state.user.role === 'ADMIN',
  isAuthenticated: state => !!(state.user && state.user.id)
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
