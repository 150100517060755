import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

function loadLayout (view) {
  return () => import(/* webpackChunkName: "layout-[request]" */ `@/layouts/${view}.vue`);
}

function loadContent (view) {
  return () => import(/* webpackChunkName: "content-[request]" */ `@/contents/${view}.vue`);
}

const ifAuthenticated = async (to, from, next) => {
  await store.dispatch('auth/validate');
  if (store.getters['auth/isAuthenticated']) {
    const user = store.getters['auth/currentUser'];
    const isAdmin = store.getters['auth/isAdmin'];
    const userClient = isAdmin ? undefined : user.client.slug;
    if (isAdmin) {
      next();
      return;
    } else if (userClient) {
      const name = 'clientdashboard';
      if (to.params.client !== userClient) {
        to.params.client = userClient;
        next({ name, params: to.params, query: to.query });
      } else if (to.name === 'dashboard') {
        next({ name, params: to.params, query: to.query });
      } else {
        next();
      }
      return;
    }
  }
  next({ name: 'login' });
};

const ifAdmin = async (to, from, next) => {
  if (store.getters['auth/isAdmin']) {
    next();
    return;
  }
  next('/');
};

const ifNotAuthenticated = async (to, from, next) => {
  await store.dispatch('auth/validate');
  if (!store.getters['auth/isAuthenticated']) {
    if (to.path === '/') {
      next({ name: 'login' });
    } else {
      next();
    }
    return;
  }
  const isAdmin = store.getters['auth/isAdmin'];
  if (isAdmin) {
    delete to.params.client;
  } else {
    const user = store.getters['auth/currentUser'];
    to.params.client = user.client.slug;
  }
  // TODO: simplify this checking
  const name = to.name === 'login' || to.name === 'forgot' || to.name === 'reset' || to.name === 'verify' || !to.name ? 'dashboard' : to.name;
  next({ name, params: to.params, query: to.query });
};

const routes: Array<RouteConfig> = [{
  component: loadLayout('FullLayout'),
  path: '/',
  beforeEnter: ifNotAuthenticated,
  children: [{
    path: '/login',
    name: 'login',
    component: loadContent('Login')
  }, {
    path: '/forgot',
    name: 'forgot',
    component: loadContent('Forgot')
  }, {
    path: '/reset/:token',
    name: 'reset',
    component: loadContent('ResetPassword')
  }, {
    path: '/verify/:token',
    name: 'verify',
    component: loadContent('Verify')
  }]
},
{
  component: loadLayout('DefaultLayout'),
  path: '',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'clients',
      component: loadLayout('MainLayout'),
      beforeEnter: ifAdmin,
      children: [{
        path: '',
        name: 'clients',
        component: loadContent('Clients'),
        meta: {
          label: 'Overview'
        }
      }, {
        path: ':id',
        name: 'client',
        component: loadContent('Client'),
        meta: {
          label: 'Individual Client'
        }
      }],
      meta: {
        label: 'Clients'
      }
    },
    {
      path: 'surveys',
      component: loadLayout('MainLayout'),
      beforeEnter: ifAdmin,
      children: [{
        path: '',
        name: 'surveys',
        component: loadContent('Surveys'),
        meta: {
          label: 'Overview'
        }
      }],
      meta: {
        label: 'Survey Management'
      }
    },
    {
      path: 'reports',
      component: loadLayout('MainLayout'),
      beforeEnter: ifAdmin,
      children: [{
        path: '',
        name: 'reports',
        component: loadContent('Reports'),
        meta: {
          label: 'Overview'
        }
      }, {
        path: ':id',
        name: 'report',
        component: loadContent('Report')
      }],
      meta: {
        label: 'Report Management'
      }
    },
    {
      path: 'settings',
      component: loadLayout('MainLayout'),
      beforeEnter: ifAdmin,
      children: [{
        path: '',
        name: 'settings',
        component: loadContent('Settings'),
        meta: {
          label: 'Overview'
        }
      }],
      meta: {
        label: 'Settings'
      }
    },
    {
      path: 'admins',
      component: loadLayout('MainLayout'),
      beforeEnter: ifAdmin,
      children: [{
        path: '',
        name: 'admins',
        component: loadContent('Admins'),
        meta: {
          label: 'Overview'
        }
      }],
      meta: {
        label: 'Administrators'
      }
    }, {
      path: '/profile',
      component: loadLayout('MainLayout'),
      children: [{
        path: 'password',
        name: 'password',
        component: loadContent('UpdatePassword'),
        meta: {
          label: 'Password'
        }
      }],
      meta: {
        label: 'Profile'
      }
    }, {
      path: '/:client',
      component: loadLayout('MainLayout'),
      children: [{
        path: '',
        name: 'clientdashboard',
        component: loadContent('ClientDashboard'),
        meta: {
          label: 'Overview'
        }
      }],
      meta: {
        label: 'Dashboard'
      }
    }, {
      path: '/',
      component: loadLayout('MainLayout'),
      beforeEnter: ifAdmin,
      children: [{
        path: '',
        name: 'dashboard',
        component: loadContent('Dashboard'),
        meta: {
          label: 'Overview'
        }
      }],
      meta: {
        label: 'Dashboard'
      }
    }]
},
{
  component: loadLayout('SurveyLayout'),
  path: '/:client/:surveySlug',
  children: [{
    path: '',
    name: 'participant',
    component: loadContent('Participant')
  }]
}];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
