import Vue from 'vue';
import axios from 'axios';

const state = {
  templates: {},
  count: {}
};

const actions = {
  async create ({ commit }, { data }) {
    return axios.post('/templates', data)
      .then(({ data }) => {
        return data;
      });
  },
  async get ({ commit }, { id, data }) {
    return axios.get(`/templates/${id}`, data)
      .then(({ data }) => {
        return data;
      });
  },
  async update ({ commit }, { id, data }) {
    return axios.put(`/templates/${id}`, data)
      .then(({ data }) => {
        return data;
      });
  },
  async delete ({ commit }, { id }) {
    return axios.delete(`/templates/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  async list ({ commit }, { key = 'default', params = {} }) {
    return axios.get('/templates', { params })
      .then(({ data }) => {
        commit('setList', { key, data });
        return data;
      });
  },
  async count ({ commit }, { key = 'default', params = {} }) {
    return axios.get('/templates/count', { params })
      .then(({ data }) => {
        commit('setCount', { key, data });
        return data;
      });
  }
};

const mutations = {
  setList (state, { key = 'default', data }) {
    if (!state.templates[key]) {
      Vue.set(state.templates, key, []);
    }
    state.templates[key].splice(0);
    state.templates[key].push(...data);
  },
  setCount (state, { key = 'default', data }) {
    Vue.set(state.count, key, data);
  }
};

const getters = {
  getList: (state) => (key = 'default') => {
    if (!state.templates[key]) {
      Vue.set(state.templates, key, []);
    }
    return state.templates[key];
  },
  getCount: (state) => (key = 'default') => {
    return state.count[key];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
