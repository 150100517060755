























import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Vuelidate from 'vuelidate';
import VueSweetalert2 from 'vue-sweetalert2';
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import VueLodash from 'vue-lodash';
import IdleVue from 'idle-vue';
import _ from 'lodash';

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 120000, // 2 minutes
  startAtIdle: false,
});

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);
Vue.use(VueLodash);

axios.defaults.baseURL =
  process.env.VUE_APP_API_BASEURL || 'http://localhost:8080/v1';
axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
  if (config.params && config.params.dl === 1) {
    config.responseType = 'blob';
  }
  return config;
});
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  ({ response }) => {
    if (!response) {
      return Promise.reject(
        new Error('Oops! Something went wrong. Please try again.')
      );
    }
    const err = response.data.error || new Error();
    return Promise.reject(err);
  }
);

@Component({
  name: 'app',
})
export default class App extends Vue {
  ready = false;

  // HOOKS
  mounted() {
    this.$store.dispatch('auth/validate').then(() => {
      this.ready = true;
      document.body.classList.add('pace-done');
    });
  }
}
