import Vue from 'vue';
import axios from 'axios';

const state = {
  clients: {},
  count: {}
};

const actions = {
  async create ({ commit }, { data }) {
    return axios.post('/clients', data)
      .then(({ data }) => {
        return data;
      });
  },
  async get ({ commit }, { id, params }) {
    return axios.get(`/clients/${id}`, { params })
      .then(({ data }) => {
        return data;
      });
  },
  async getBySlug ({ commit }, { slug, params }) {
    return axios.get(`/clients/bySlug/${slug}`, { params })
      .then(({ data }) => {
        return data;
      });
  },
  async update ({ commit }, { id, data }) {
    return axios.put(`/clients/${id}`, data)
      .then(({ data }) => {
        return data;
      });
  },
  async delete ({ commit }, { id }) {
    return axios.delete(`/clients/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  async deleteMany ({ commit }, { params = {} } = {}) {
    return axios.delete('/clients')
      .then(({ data }) => {
        return data;
      });
  },
  async list ({ commit }, { key = 'default', params = {} }) {
    return axios.get('/clients', { params })
      .then(({ data }) => {
        commit('setList', { key, data });
        return data;
      });
  },
  async count ({ commit }, { key = 'default', params = {} }) {
    return axios.get('/clients/count', { params })
      .then(({ data }) => {
        commit('setCount', { key, data });
        return data;
      });
  },
  async export ({ commit }, { params = {} }) {
    const requestParams = {
      ...params,
      dl: 1
    };
    return axios.get('/clients', { params: requestParams })
      .then(({ data }) => {
        return data;
      });
  }
};

const mutations = {
  setList (state, { key = 'default', data }) {
    if (!state.clients[key]) {
      Vue.set(state.clients, key, []);
    }
    state.clients[key].splice(0);
    state.clients[key].push(...data);
  },
  setCount (state, { key = 'default', data }) {
    Vue.set(state.count, key, data);
  }
};

const getters = {
  getList: (state) => (key = 'default') => {
    if (!state.clients[key]) {
      Vue.set(state.clients, key, []);
    }
    return state.clients[key];
  },
  getCount: (state) => (key = 'default') => {
    return state.count[key];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
