import Vue from 'vue';
import axios from 'axios';

const state = {
  surveys: {},
  count: {},
  participant: {},
  participantCount: {},
  responses: {},
  lastClient: { id: null }
};

const actions = {
  async create ({ commit }, { data }) {
    return axios.post('/surveys', data)
      .then(({ data }) => {
        commit('setLastClient', data.client);
        return data;
      });
  },
  async get ({ commit }, { id }) {
    return axios.get(`/surveys/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  async update ({ commit }, { id, data }) {
    return axios.put(`/surveys/${id}`, data)
      .then(({ data }) => {
        commit('setLastClient', data.client);
        return data;
      });
  },
  async delete ({ commit }, { id }) {
    return axios.delete(`/surveys/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  async deleteMany ({ commit }, { params = {} } = {}) {
    return axios.delete('/surveys', { params })
      .then(({ data }) => {
        return data;
      });
  },
  async deleteResponses ({ commit }, { params = {} } = {}) {
    return axios.delete('/surveys/responses', { params })
      .then(({ data }) => {
        return data;
      });
  },
  async list ({ commit }, { key = 'default', params = {} }) {
    return axios.get('/surveys', { params })
      .then(({ data }) => {
        commit('setList', { key, data });
        return data;
      });
  },
  async count ({ commit }, { key = 'default', params = {} }) {
    return axios.get('/surveys/count', { params })
      .then(({ data }) => {
        commit('setCount', { key, data });
        return data;
      });
  },
  async participantList ({ commit }, { id, params = {} }) {
    return axios.get(`/surveys/${id}/participants`, { params })
      .then(({ data }) => {
        commit('setParticipantList', { key: id, data });
        return data;
      });
  },
  async exportParticipantList ({ commit }, { id, params = {} }) {
    const requestParams = {
      ...params,
      dl: 1
    };
    return axios.get(`/surveys/${id}/participants`, { params: requestParams })
      .then(({ data }) => {
        return data;
      });
  },
  async participantCount ({ commit }, { id, params = {} }) {
    return axios.get(`/surveys/${id}/participants/count`, { params })
      .then(({ data }) => {
        commit('setParticipantCount', { key: id, data });
        return data;
      });
  },
  async getSummary ({ commit }, { params = {} }) {
    return axios.get('/surveys/summary', { params })
      .then(({ data }) => {
        // commit('setResponseList', { key: id, data })
        return data;
      });
  },
  async responseList ({ commit }, { id, params = {} }) {
    return axios.get(`/surveys/${id}/responses`, { params })
      .then(({ data }) => {
        commit('setResponseList', { key: id, data });
        return data;
      });
  },
  async getResponses ({ commit }, { id, params }) {
    return axios.get(`/surveys/${id}/responses`, { params })
      .then(({ data }) => {
        return data;
      });
  }
};

const mutations = {
  setList (state, { key = 'default', data }) {
    if (!state.surveys[key]) {
      Vue.set(state.surveys, key, []);
    }
    state.surveys[key].splice(0);
    state.surveys[key].push(...data);
  },
  setCount (state, { key = 'default', data }) {
    Vue.set(state.count, key, data);
  },
  setParticipantList (state, { key = 'default', data }) {
    if (!state.surveys[key]) {
      Vue.set(state.participant, key, []);
    }
    state.participant[key].splice(0);
    state.participant[key].push(...data);
  },
  setParticipantCount (state, { key = 'default', data }) {
    Vue.set(state.participantCount, key, data);
  },
  setResponseList (state, { key = 'default', data }) {
    if (!state.responses[key]) {
      Vue.set(state.responses, key, []);
    }
    state.responses[key].splice(0);
    state.responses[key].push(...data);
  },
  setLastClient (state, data) {
    Vue.set(state, 'lastClient', data);
  }
};

const getters = {
  getList: (state) => (key = 'default') => {
    if (!state.surveys[key]) {
      Vue.set(state.surveys, key, []);
    }
    return state.surveys[key];
  },
  getCount: (state) => (key = 'default') => {
    return state.count[key];
  },
  getResponseList: (state) => (key = 'default') => {
    if (!state.responses[key]) {
      Vue.set(state.responses, key, []);
    }
    return state.responses[key];
  },
  getLastClient: (state) => {
    return state.lastClient;
  },
  getParticipantList: (state) => (key = 'default') => {
    if (!state.participant[key]) {
      Vue.set(state.participant, key, []);
    }
    return state.participant[key];
  },
  getParticipantCount: (state) => (key = 'default') => {
    return state.participantCount[key];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
